import { IRate, IRateBase, IRateBaseFront } from '../../models'
import { getPennies, roundByEpsilon, stringToNumber } from '../../utils'


export enum ETariffTypeTitle {
    Percent = 'Percent',
    PercentPerYear = 'PercentPerYear',
    Rubles = 'Rubles',
}

const FIAT_RATIO = 100
const PERCENT_RATIO = 100000

const getValueForInput = (value: string | number, divider: number): number => {
    if (!value || !divider) {
        return 0
    }

    if (typeof value === 'string') {
        const parsed = stringToNumber(value)
        return roundByEpsilon(parsed / divider, divider)
    }

    return roundByEpsilon(value / divider, divider)
}

export const rateBackToFront = (rate: IRateBase): IRateBaseFront => {
    const minimum_show =
        getValueForInput(rate.min_percent, PERCENT_RATIO) ||
        getValueForInput(rate.minimum, FIAT_RATIO)
    const maximum_show =
        getValueForInput(rate.max_percent, PERCENT_RATIO) ||
        getValueForInput(rate.maximum, FIAT_RATIO)
    const amount_show =
        getValueForInput(rate.annual_percentage, PERCENT_RATIO) ||
        getValueForInput(rate.amount_percentage, PERCENT_RATIO)

    const minimum_show_type = rate.minimum ? ETariffTypeTitle.Rubles : ETariffTypeTitle.Percent
    const maximum_show_type = rate.maximum ? ETariffTypeTitle.Rubles : ETariffTypeTitle.Percent
    const amount_show_type = rate.amount_percentage
        ? ETariffTypeTitle.Percent
        : ETariffTypeTitle.PercentPerYear

    return {
        ...rate,
        minimum_show: minimum_show.toString().replace('.', ','),
        minimum_show_type,
        maximum_show: maximum_show.toString().replace('.', ','),
        maximum_show_type,
        amount_show: amount_show.toString().replace('.', ','),
        amount_show_type,
        error: false,
    }
}

export const rateFrontToBack = (rate: IRateBaseFront, includeId?: boolean): IRate => {
    const mappedRate: IRate = {
        amount_percentage: '',
        annual_percentage: '',
        fee_code: rate.fee_code,
        max_percent: '',
        maximum: 0,
        min_percent: '',
        minimum: 0,
    }

    if (includeId) {
        mappedRate.id = rate.id
    }

    if (rate.minimum_show_type === ETariffTypeTitle.Rubles) {
        mappedRate.minimum = getPennies(stringToNumber(rate.minimum_show)) || 0
    } else {
        mappedRate.min_percent = `${stringToNumber(rate.minimum_show) * PERCENT_RATIO || ''}`
    }

    if (rate.maximum_show_type === ETariffTypeTitle.Rubles) {
        mappedRate.maximum = getPennies(stringToNumber(rate.maximum_show)) || 0
    } else {
        mappedRate.max_percent = `${stringToNumber(rate.maximum_show) * PERCENT_RATIO || ''}`
    }

    if (rate.amount_show_type === ETariffTypeTitle.Percent) {
        mappedRate.amount_percentage = `${stringToNumber(rate.amount_show) * PERCENT_RATIO || ''}`
    } else {
        mappedRate.annual_percentage = `${stringToNumber(rate.amount_show) * PERCENT_RATIO || ''}`
    }

    return mappedRate
}